import { postApiCall } from "../Handlers/HttpHandler"
import { toast } from "react-toastify"

export const getTransactions = async(token, offset, limit, all = null, player_id = null) => {
    let body = {
        token: token,
        offset: offset,
        limit: limit
    }

    if (all !== null) body.all = all;
    if (player_id !== null) body.player_id = player_id;
    
    let response = await postApiCall("transaction/get", body);

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
        return null;
    }
}
import React, { useEffect, useState } from "react";
import { getWithExpiry } from "../../../../Domain/Helpers/LocalStorageHelper";
import { toast } from "react-toastify";
import UILabelInput from "../../../../Domain/Components/UI/LabelInput/LabelInput";
import { inputType } from "../../../../Domain/Constants/InputType";
import { formatArrayForSelectInput } from "../../../../Domain/Helpers/SelectInputHelper";
import UIButton from "../../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../../Domain/Constants/StyleType";
import { useNavigate } from "react-router-dom";
import { getAllProviders } from "../../../../Infrastructure/Services/ProviderService";
import { createGame } from "../../../../Infrastructure/Services/GameService";

function CreateGamePage(props) {
    const [providers, setProviders] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const token = getWithExpiry("token");

        if (!token) {
            props.setLoggedIn(false);
            return;
        }

        let roles = getWithExpiry("roles");

        if (!roles) {
            props.setLoggedIn(false);
            return;
        }

        if (roles.filter(x => x.includes('super-admin')).length === 0) {
            toast.error("Access denied");
            navigate("/game");
            return;
        }

        getAllProviders(token).then((data) => {
            if (data) {
                setProviders(data);
            } else {
                toast.error("Failed to load vendors");
            }
        }).catch((ex) => {
            toast.error(ex)
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            let token = getWithExpiry('token');
            if (token === null) {
                props.setLoggedIn(false);
                return;
            }

            const form = e.target;
            const formData = new FormData(form);
            let formJson = Object.fromEntries(formData.entries());

            let error = false;

            if (formJson.name.length < 3 || formJson.name.length > 50) {
                toast.error("Name length must be between 3 and 50.");
                error = true;
            }

            if (formJson.providerCode.length < 3 || formJson.providerCode.length > 80) {
                toast.error("Vendor Code length must be between 3 and 80.");
                error = true;
            }

            if (error) return;

            let success = await createGame(token, formJson);

            if (success) {
                toast.success("Game created successfully");
                navigate("/game");
            }
        } catch (ex) {
            toast.error(ex)
        }
    }

    return (<>
        {providers &&
            <div className="container">
                <div className="d-flex justify-content-between mt-2">
                    <h3>Create Game</h3>
                </div>
                <form className="mt-2" method="post" onSubmit={handleSubmit}>
                    <UILabelInput inputType={inputType.TEXT}
                        inputId={"name"}
                        labelText={"Name"}
                        required={true} />
                    <UILabelInput inputType={inputType.TEXT}
                        inputId={"providerCode"}
                        labelText={"Vendor Code"}
                        required={true} />
                    <UILabelInput inputType={inputType.SELECT}
                        inputId={"providerId"}
                        labelText={"Vendor"}
                        required={true}
                        selectOptions={formatArrayForSelectInput(providers, "name")} />

                    <div className="d-flex justify-content-end mt-2">
                        <UIButton buttonType={"submit"} buttonText={"Create"} styleType={styleType.FLEXPINK} />
                    </div>
                </form>

            </div>
        }
    </>
    )
}

export default CreateGamePage;
import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { getAllCountries } from "../../../Infrastructure/Services/CountryService";
import { getAllLanguages } from "../../../Infrastructure/Services/LanguageService";
import { createPlayer } from "../../../Infrastructure/Services/PlayerService";
import { inputType } from "../../../Domain/Constants/InputType";
import UILabelInput from "../../../Domain/Components/UI/LabelInput/LabelInput";
import UIButton from "../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../Domain/Constants/StyleType";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";

function CreatePlayerPage(props) {
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);

    const formRef = useRef({});

    const navigate = useNavigate();

    useEffect(() => {
        const token = getWithExpiry("token");

        if (!token) {
            props.setLoggedIn(false);
            return;
        }

        let roles = getWithExpiry("roles");

        if (!roles) {
            props.setLoggedIn(false);
            return;
        }

        if (roles.filter(x => x.includes('super-admin')).length === 0) {
            toast.error("Access denied");
            navigate("/game");
            return;
        }

        getAllCountries(token).then((data) => {
            if (data) {
                setCountries(data);
            } else {
                toast.error("Failed to load countries");
            }
        }).catch((ex) => {
            toast.error(ex)
        })

        getAllLanguages(token).then((data) => {
            if (data) {
                setLanguages(data);
            } else {
                toast.error("Failed to load languages");
            }
        }).catch((ex) => {
            toast.error(ex)
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let token = getWithExpiry('token');
            if (token === null) {
                props.setLoggedIn(false);
                return;
            }

            const form = e.target;
            const formData = new FormData(form);
            let formJson = Object.fromEntries(formData.entries());

            let error = false;

            if (formJson.username.length < 5 || formJson.username.length > 50) {
                toast.error("Username length must be between 5 and 50.");
                error = true;
            }

            const passRegex = new RegExp('^[a-zA-Z0-9]{3,30}$');

            if (!passRegex.test(formJson.password)) {
                toast.error("The password must contain a lower case, uppercase and number, and it's length should be between 3 and 30.");
                error = true;
            }

            if (error) return;

            let success = await createPlayer(token, formJson);

            if (success) {
                toast.success("Player created successfully");
                formRef.current.reset();
            }
        } catch (ex) {
            toast.error(ex)
        }
    }

    return (<>
        {countries && languages &&
            <div className="container">
                <div className="d-flex justify-content-between mt-2">
                    <h3>Create Player</h3>
                </div>
                <form className="mt-2" method="post" onSubmit={handleSubmit} ref={formRef}>
                    <UILabelInput inputType={inputType.TEXT}
                        inputId={"username"}
                        labelText={"Username"}
                        required={true} />
                    <UILabelInput inputType={inputType.PASSWORD}
                        inputId={"password"}
                        labelText={"Password"}
                        required={true} />
                    <UILabelInput inputType={inputType.SELECT}
                        inputId={"languageId"}
                        labelText={"Language"}
                        required={true}
                        selectOptions={formatArrayForSelectInput(languages, "name")} />
                    <UILabelInput inputType={inputType.SELECT}
                        inputId={"countryId"}
                        labelText={"Country"}
                        required={true}
                        selectOptions={formatArrayForSelectInput(countries, "name")} />

                    <div className="d-flex justify-content-end mt-2">
                        <UIButton buttonType={"submit"} buttonText={"Create"} styleType={styleType.FLEXPINK} />
                    </div>
                </form>

            </div>
        }
    </>
    )
}

export default CreatePlayerPage;
import { postApiCall } from "../Handlers/HttpHandler";
import { setWithExpiry } from "../../Domain/Helpers/LocalStorageHelper";

export const loginCall = async (uss, pass) => {
    var result = await postApiCall("auth/login", {
        username: uss,
        password: pass
    });

    if (result.StatusCode === 0) {
        setWithExpiry("token", result.data.token, process.env.REACT_APP_TOKEN_DURATION * 3600 * 1000);
        setWithExpiry("roles", result.data.roles, process.env.REACT_APP_TOKEN_DURATION * 3600 * 1000);
    }

    return result;
}

import React from "react";
import PropTypes from "prop-types"
import "./style.css"
import {Link} from "react-router-dom"

function GameLink(props) {
    return(
        <div className="col-md-4">
            <Link to={`/game/play?id=${props.id}`} className="game-link">
            <span>{props.gameName}</span>
        </Link>
        </div>
        
    )
}

GameLink.propTypes = {
    id: PropTypes.string,
    gameName: PropTypes.string
}

export default GameLink;
import { postApiCall } from "../Handlers/HttpHandler"
import { toast } from "react-toastify"

export const getAllCountries = async(token) => {
    const response = await postApiCall("country/get", {token: token});

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
        return null;
    }
}
import React, { useEffect, useState } from "react";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { toast } from "react-toastify";
import UIButton from "../../../Domain/Components/UI/Button/Button";
import UILabelInput from "../../../Domain/Components/UI/LabelInput/LabelInput";
import { styleType } from "../../../Domain/Constants/StyleType";
import { inputType } from "../../../Domain/Constants/InputType";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { useNavigate } from "react-router-dom";
import { getAllLanguages, selectLanguage } from "../../../Infrastructure/Services/LanguageService";

function SelectLanguagePage(props) {
    const [languages, setLanguages] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = getWithExpiry("token");

        if (!token) {
            props.setLoggedIn(false);
            return;
        }

        getAllLanguages(token).then((data) => {
            if (!data) {
                toast.error("Failed to load languages");   
                return;
            } 
            setLanguages(data);
            if (data.length === 0) {
                toast.warning("No languages found.")
            }
        }).catch((ex) => {
            toast.error(ex);
        })
    }, [])

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
            let token = getWithExpiry('token');
            if (token === null) {
                props.setLoggedIn(false);
                return;
            }

            const form = e.target;
            const formData = new FormData(form);
            let formJson = Object.fromEntries(formData.entries());

            let success = await selectLanguage(token, formJson.languageId);

            if (success) {
                toast.success("Language selected successfully");
                navigate("/game");
            }
        } catch (ex) {
            toast.error(ex)
        }
    }
    return(<>
        {languages &&
            <div className="container">
                <div className="d-flex justify-content-between mt-2">
                    <h3>Select Langugae</h3>
                </div>
                <form className="mt-2" method="post" onSubmit={handleSubmit}>

                    <UILabelInput inputType={inputType.SELECT}
                        inputId={"languageId"}
                        labelText={"Language"}
                        required={true}
                        selectOptions={formatArrayForSelectInput(languages, "name")} />

                    <div className="d-flex justify-content-end mt-2">
                        <UIButton buttonType={"submit"} buttonText={"Select"} styleType={styleType.FLEXPINK} />
                    </div>
                </form>

            </div>
        }
    </>)
}

export default SelectLanguagePage;
import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { getGameLaunchUrl } from "../../../../Infrastructure/Services/GameService";
import { getWithExpiry } from "../../../../Domain/Helpers/LocalStorageHelper";
import { toast } from "react-toastify";
import "./style.css";
import { mobileBrowserCheck } from "../../../../Domain/Helpers/BrowserDetectHelper";

function GamePlayPage(props) {
    const [gameUrl, setGameUrl] = useState();
    const [gameName, setGameName] = useState();
    const [demo, setDemo] = useState(false);
    const [searchParams, setSeatchParams] = useSearchParams();

    useEffect(() => {
        let iframe = document.getElementById("main-frame");
        let isMobile = mobileBrowserCheck();
        if(isMobile) {
            iframe.style.width="100vw";
            iframe.style.height="100vh";
            iframe.style.position="absolute";
            iframe.style.top="0";
            iframe.style.left="0";
            iframe.style.margin="0";
            iframe.style.backgroundColor="var(--fade-black)";
            iframe.style.zIndex="9999";
            iframe.style.maxWidth="9999";
            iframe.style.maxHeight="9999";
        }
      }, [])

    const getGameUrl = async () => {
        const token = getWithExpiry("token");
        if (!token) {
            props.setLoggedIn(false);
            return;
        }

        let resp = await getGameLaunchUrl(token, searchParams.get("id"), window.location.href, demo, mobileBrowserCheck() ? "MOBILE" : "DESKTOP");
        if (resp) { 
            setGameName(resp.gameName);
            setGameUrl(resp.url);
        } else {
            setGameName("Game Not Found");
        }
    }

    useEffect(() => {
        getGameUrl().then().catch((ex) => {
            toast.error(ex)
        })
    }, [demo])

    const demoCheckChaned = (e) => {
        setDemo(e.target.checked);
    }

    return (<div className="container">

            <div className="game-title">{gameName}</div>
            <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" id="demo-check" onChange={demoCheckChaned}/>
                    <label class="form-check-label" for="demo-check">
                        Play in demo mode
                    </label>
            </div>
        
        <iframe className="primary-iframe mt-2" src={gameUrl} title="primar" id="main-frame" allow="fullscreen" />
    </div>)
}

export default GamePlayPage;
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { getAllPlayers } from "../../../Infrastructure/Services/PlayerService";
import { assignRole, getAllRoles } from "../../../Infrastructure/Services/RoleService";
import UIButton from "../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../Domain/Constants/StyleType";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { inputType } from "../../../Domain/Constants/InputType";
import UILabelInput from "../../../Domain/Components/UI/LabelInput/LabelInput";
import { toast } from "react-toastify";

function AssignRolePage(props) {
    const [players, setPlayers] = useState([]);
    const [roles, setRoles] = useState([]);

    const formRef = useRef({});

    const navigate = useNavigate();

    useEffect(() => {
        const token = getWithExpiry("token");

        if (!token) {
            props.setLoggedIn(false);
            return;
        }

        let roles = getWithExpiry("roles");

        if (!roles) {
            props.setLoggedIn(false);
            return;
        }

        if (roles.filter(x => x.includes('super-admin')).length === 0) {
            toast.error("Access denied");
            navigate("/game");
            return;
        }

        getAllPlayers(token).then((data) => {
            if (data) {
                setPlayers(data);
            } else {
                toast.error("Failed to load players");
            }
        }).catch((ex) => {
            toast.error(ex)
        })

        getAllRoles(token).then((data) => {
            if (data) {
                setRoles(data);
            } else {
                toast.error("Failed to load roles");
            }
        }).catch((ex) => {
            toast.error(ex)
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let token = getWithExpiry('token');
            if (token === null) {
                props.setLoggedIn(false);
                return;
            }

            const form = e.target;
            const formData = new FormData(form);
            let formJson = Object.fromEntries(formData.entries());

            let success = await assignRole(token, formJson);

            if (success) {
                toast.success("Role assigned successfully");
                formRef.current.reset();
            }
        } catch (ex) {
            toast.error(ex)
        }
    }

    return (<>
        {players && roles &&
            <div className="container">
                <div className="d-flex justify-content-between mt-2">
                    <h3>Assign Role</h3>
                </div>
                <form className="mt-2" method="post" onSubmit={handleSubmit} ref={formRef}>
                    <UILabelInput inputType={inputType.SELECT}
                        inputId={"player_id"}
                        labelText={"Player"}
                        required={true}
                        selectOptions={formatArrayForSelectInput(players, "username")} />
                    <UILabelInput inputType={inputType.SELECT}
                        inputId={"role_id"}
                        labelText={"Role"}
                        required={true}
                        selectOptions={formatArrayForSelectInput(roles, "name")} />

                    <div className="d-flex justify-content-end mt-2">
                        <UIButton buttonType={"submit"} buttonText={"Assign"} styleType={styleType.FLEXPINK} />
                    </div>
                </form>

            </div>
        }
    </>
    )
}

export default AssignRolePage;
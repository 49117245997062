import { toast } from "react-toastify";
import { postApiCall } from "../Handlers/HttpHandler"

export const getGames = async(token, offset, limit, name) => {
    let games = await postApiCall("games/get", {
        token: token,
        offset: offset,
        limit: limit,
        name: name
    });

    if (games.StatusCode === 0) {
        return games.data;
    } else {
        toast.error(games.StatusMessage);
        return null;
    }
}

export const getGameLaunchUrl = async(token, gameCode, callbackUrl, demo, device) => {
    let gamesUrlResp = await postApiCall("games/launchURL", {
        token: token,
        gameId: Number(gameCode),
        callbackURL: callbackUrl,
        demo: demo,
        device: device
    });

    if (gamesUrlResp.StatusCode === 0) {
        return gamesUrlResp.data;
    } else {
        toast.error(gamesUrlResp.StatusMessage);
        return null;
    }
}

export const createGame = async(token, body) => {
    body.token = token;

    let response = await postApiCall("games/create", body);

    if (response.StatusCode === 0) {
        return true;
    } else {
        toast.error(response.StatusMessage);
        return false;
    }
}
import { toast } from "react-toastify";
import { postApiCall } from "../Handlers/HttpHandler";

export const createBalance = async(body, token) => {
    body.token = token;

    const response = await postApiCall("player-balances/create", body);

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
    }

    return false;
}

export const selectBalance = async(balanceId, token) => {
    let body = {
        token: token,
        player_balance_id: balanceId
    }

    const response = await postApiCall("player-balances/select", body);

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
    }

    return false;
}

export const getAllBalances = async(token) => {
    const response = await postApiCall("player-balances/get", {token: token});

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
        return null;
    }
}
import React, { useEffect, useState } from "react";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { getAllCurrencies } from "../../../Infrastructure/Services/CurrencyService";
import { toast } from "react-toastify";
import UILabelInput from "../../../Domain/Components/UI/LabelInput/LabelInput";
import { inputType } from "../../../Domain/Constants/InputType";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import UIButton from "../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../Domain/Constants/StyleType";
import { createBalance } from "../../../Infrastructure/Services/BalanceService";
import { useNavigate } from "react-router-dom";

function CreateBalancePage(props) {
    const [currencies, setCurrencies] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const token = getWithExpiry("token");

        if (!token) {
            props.setLoggedIn(false);
            return;
        }

        getAllCurrencies(token).then((data) => {
            if (data) {
                setCurrencies(data);
            } else {
                toast.error("Failed to load currencies");
            }
        }).catch((ex) => {
            toast.error(ex)
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            let token = getWithExpiry('token');
            if (token === null) {
                props.setLoggedIn(false);
                return;
            }

            const form = e.target;
            const formData = new FormData(form);
            let formJson = Object.fromEntries(formData.entries());

            let success = await createBalance(formJson, token);

            if (success) {
                toast.success("Balance created successfully, you can now select it as a current balance");
                navigate("/balance/select");
            }
        } catch (ex) {
            toast.error(ex)
        }
    }

    return (<>
        {currencies &&
            <div className="container">
                <div className="d-flex justify-content-between mt-2">
                    <h3>Create Balance</h3>
                </div>
                <form className="mt-2" method="post" onSubmit={handleSubmit}>
                    <UILabelInput inputType={inputType.NUMBER}
                        inputId={"realBalance"}
                        labelText={"Real Balance"}
                        required={true} />
                    <UILabelInput inputType={inputType.NUMBER}
                        inputId={"bonusBalance"}
                        labelText={"Bonus Balance"}
                        required={true} />
                    <UILabelInput inputType={inputType.SELECT}
                        inputId={"currencyId"}
                        labelText={"Currency"}
                        required={true}
                        selectOptions={formatArrayForSelectInput(currencies, "iso_code")} />

                    <div className="d-flex justify-content-end mt-2">
                        <UIButton buttonType={"submit"} buttonText={"Create/Update"} styleType={styleType.FLEXPINK} />
                    </div>
                </form>

            </div>
        }
    </>
    )
}

export default CreateBalancePage;
import React, { useEffect, useState } from "react";
import { getWithExpiry } from "../../../../Domain/Helpers/LocalStorageHelper";
import { getGames } from "../../../../Infrastructure/Services/GameService";
import GameLink from "../../../../Domain/Components/Functional/GameLink/GameLink";
import UISearchBar from "../../../../Domain/Components/UI/SearchBar/SearchBar";
import UIButton from "../../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../../Domain/Constants/StyleType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";

function GameListPage(props) {
    const [games, setGames] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(false);
    const limit = 12;
    const [search, setSearch] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadGames = async () => {
        const token = getWithExpiry("token");
        if (!token) {
            props.setLoggedIn(false);
            return;
        }

        let response = await getGames(token, (page - 1) * limit, limit, search);
        if (response.length > 0 || page === 1) {
            setGames(response);
        }

        if (response) {
            if (response.length < limit) {
                setLastPage(true);
            }
        }

        if (response.length === 0 && page !== 1) {
            setPage(page - 1);
        }
    }

    useEffect(() => {
        setLoading(true);
        loadGames().then(() => {
            setLoading(false)
        });
    }, [page]);

    useEffect(() => {
        if (page === 1) {
            setLoading(true);
            loadGames().then(() => {
                setLoading(false)
            });
        } else {
            setPage(1);
        }
    }, [search])

    const onSearchInputChange = (e) => {
        const inputVal = e.target.value;
        if (!inputVal || inputVal === "") {
            setSearch(null);
        }
    }

    const goOnPrevPage = () => {
        if (page === 1) return;

        setPage(page - 1);

        if (lastPage) setLastPage(false);
    }

    return (<>
        <div className="container">
            <div className="d-flex justify-content-between mt-2">
                <p className="h3">Games</p>
                <UISearchBar onSearch={(val) => setSearch(val)} onInputChange={onSearchInputChange} />
            </div>
            <div className="row mt-2">
                {games === null ?
                    <p className="text-danger">Failed to load games</p> :
                    loading ?
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div> :
                        games.length === 0 ? <span className="text-secondary">No games found</span> :
                            <>
                                {games.map((game, index) => {
                                    return <React.Fragment key={index}>
                                        <GameLink id={game.id}
                                            gameName={game.name} />
                                    </React.Fragment>
                                })}
                                <div className="col-12">
                                    <div className="d-flex justify-content-between mt-2">
                                        <UIButton styleType={styleType.FADEBLACK} disabled={page === 1} onClick={() => goOnPrevPage()}
                                            icon={<FontAwesomeIcon icon={faBackward} />} />

                                        <h5>{page}</h5>

                                        <UIButton styleType={styleType.FADEBLACK} disabled={lastPage} onClick={() => setPage(page + 1)}
                                            icon={<FontAwesomeIcon icon={faForward} />} />
                                    </div>
                                </div>
                            </>
                }
            </div>
        </div>
    </>)
}

export default GameListPage;
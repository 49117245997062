import { postApiCall } from "../Handlers/HttpHandler"
import { toast } from "react-toastify"

export const getAllProviders = async(token) => {
    let response = await postApiCall("provider/get", {token: token});

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
        return null;
    }
}
import { postApiCall } from "../Handlers/HttpHandler";
import { toast } from "react-toastify"

export const getAllRoles = async(token) => {
    const response = await postApiCall("roles/get", {token: token});

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
        return null;
    }
}

export const assignRole = async(token, body) => {
    body.token = token;

    const response = await postApiCall("roles/assign", body);
    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
        return null;
    }
}
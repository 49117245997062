import { postApiCall } from "../Handlers/HttpHandler";
import { toast } from "react-toastify"

export const createPlayer = async(token, body) => {
    body.token = token;

    const response = await postApiCall("player/create", body);

    if (response.StatusCode === 0) {
        return true;
    } else {
        toast.error(response.StatusMessage);
        return false;
    }
}

export const getAllPlayers = async(token) => {
    const response = await postApiCall("player/get", {token: token});

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
        return null;
    }
}
import { postApiCall } from "../Handlers/HttpHandler";
import { toast } from "react-toastify";

export const getAllLanguages = async(token) => {
    let langResponse = await postApiCall("language/get", {token: token});

    if (langResponse.StatusCode === 0) {
        return langResponse.data;
    } else {
        toast.error(langResponse.StatusMessage);
        return null;
    }
}

export const selectLanguage = async(token, languageId) => {
    let response = await postApiCall("language/select", {
        token: token,
        languageId: languageId
    });

    if (response.StatusCode === 0) {
        return response.data;
    } else {
        toast.error(response.StatusMessage);
        return false;
    }
}